import { Button } from "components/buttons"
import { FormControl, Label } from "components/Form"
import MobileModal from "components/MobileModal"
import _ from "lodash"
import React, { useState, useEffect, useRef } from "react"
import { Col, Form, Modal } from "react-bootstrap"
import styled from "styled-components"
import { breakpoints } from "utils"
import ModalContent from "../../dashboardPage/Modal/ModalContent"
import ThreadInput from "../../threadPage/ThreadInput"

const Container = styled.div``
const CustomModal = styled(Modal)`
  /* &.modal.fade:not(.in).bottom .modal-dialog { */
  /* transform: translate3d(0, 25%, 0) !important; */
  /* } */
  /* bottom: 0; */
`

interface Props {
  show: boolean
  onClose: Function
  onSubmit: Function
  data: any
  isEditing: boolean
}

const TopicForm: React.FC<Props> = props => {
  const [form, setForm] = useState(props.data)

  useEffect(() => {
    setForm(props.data)
  }, [props.data])

  const onChange = (field: string, value: string) => {
    setForm(
      Object.assign({}, form, {
        [field]: value,
      })
    )
  }

  const getForm = () => {
    props.onSubmit({
      id: form.id,
      text: form.text,
      title: form.title,
    })
  }

  const getContent = () => {
    return (
      <ModalContent
        title={props.isEditing ? "Edit Thread" : "New Thread"}
        subtitle=""
        footer={<Button onClick={() => getForm()}>Submit</Button>}
        onClose={props.onClose}
        bodyStyle={{
          paddingTop: 0,
        }}
      >
        <Form.Row>
          <Form.Group as={Col}>
            <Label>Title</Label>
            <FormControl
              style={{ fontSize: "14px", color: "#173A74" }}
              onChange={(e: any) => onChange("title", e.target.value)}
              type="text"
              maxLength={300}
              placeholder=""
              value={form.title}
            />
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Form.Group as={Col}>
            <Label>Question or comment</Label>
            <Form.Control
              style={{ fontSize: "14px", color: "#173A74" }}
              as="textarea"
              rows={5}
              onChange={(e: any) => onChange("text", e.target.value)}
              type="textarea"
              maxLength={300}
              placeholder=""
              value={form.text}
            />
          </Form.Group>
        </Form.Row>
      </ModalContent>
    )
  }

  const isPhoneView =
    typeof window !== `undefined` && window.innerWidth <= breakpoints.phone

  if (isPhoneView) {
    return <MobileModal show={props.show}>{getContent()}</MobileModal>
  }

  return (
    <Container>
      <CustomModal size="lg" show={props.show} onClose={props.onClose}>
        {getContent()}
      </CustomModal>
    </Container>
  )
}

export default TopicForm

import { navigate } from "gatsby"
import formatDistance from "date-fns/formatDistance"
import { FaRegClock, FaRegTrashAlt } from "react-icons/fa"
// import { GoPrimitiveDot } from "react-icons/go"
import { FiMessageCircle } from "react-icons/fi"
import React, { useEffect, useState } from "react"
import { Col, Row } from "react-bootstrap"
import * as _ from "lodash"
import { StringParam, useQueryParam } from "use-query-params"
import styled, { css } from "styled-components"
import { breakpoints } from "utils"
import { ForumThread, ForumActivity } from "api/types"
import { FaEdit, FaRegEdit } from "../../../AppReactIcons"
import TopicForm from "../EditTopic/TopicForm"
import getProfileFromSessionStorage from "../../QueryUserProfile/getProfileFromSessionStorage"
import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api-graphql"
import {
  apiFetchForumThreads,
  apiFetchMyForumActivity,
  apiGetSingleForumThread,
  apiPutForumThread,
  apiRemoveForumThread,
} from "../../../api/queries"
import Amplify, { Auth } from "aws-amplify"
import { AWS_CONFIG } from "../../../../aws-config"

const Container = styled.div`
  background: rgba(0, 0, 0, 0.03);
  border-radius: 7px;
  padding: 18px;
  margin-bottom: 10px;
  position: relative;
`
const Title = styled.span`
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 21px;
  color: ${props => props.theme.colors.secondary};
  margin-bottom: 7px;
  cursor: pointer;
`
const DescriptionWrapper = styled(Row)``
const Description = styled(Col)`
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: ${props => props.theme.colors.secondary};
  margin-bottom: 12px;
`
const Info = styled.span`
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 17px;
  color: ${props => props.theme.colors.secondary};
  @media (max-width: ${breakpoints.tablet}px) {
    & span {
      display: none;
    }
  }
`
const Header = styled(Row)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 17px;
  color: ${props => props.theme.colors.secondary};
  & span {
    margin-left: 6px;
  }
`
const FooterSection = styled.div`
  display: flex;
  align-items: center;
`
const iconStyles = css`
  stroke: #666;
  cursor: pointer;
`
const EditIcon: any = styled(FaRegEdit)`
  ${iconStyles}
`

const TrashIcon: any = styled(FaRegTrashAlt)`
  ${iconStyles};
  margin-left: 12px;
`
// const PinIcon = styled(AiFillPushpin)`
//   margin-left: auto;
// `
// const Separator = styled(GoPrimitiveDot)`
//   margin: 0 10px;
// `
const enum ErrorType {
  title = "title",
  content = "content",
}
export type StartThreadError = {
  errorType: ErrorType | null
  message: string | null
}

interface Props {
  createdByLoggedInUser?: boolean
  editable?: boolean
  deletable?: boolean
  onEdit: Function
  onDelete: Function
}

const ThreadItem: React.FC<ForumThread & Props> = props => {
  const [searchIsVisible, setSearchIsVisible] = useState<boolean>(false)
  const [reachedEnd, setReachedEnd] = useState<boolean>(false)
  const [error, setError] = useState<StartThreadError>({
    errorType: null,
    message: null,
  })

  useEffect(() => {
    Amplify.configure(AWS_CONFIG)
    if (
      typeof window !== `undefined` &&
      window.innerWidth <= breakpoints.tablet
    ) {
      setSearchIsVisible(true)
    }
  }, [])

  const lastUpdatedAt = props?.lastUpdatedOn
    ? formatDistance(new Date(props?.lastUpdatedOn), new Date())
    : ""
  return (
    <Container>
      <div
        style={{ cursor: "pointer" }}
        onClick={() => navigate(`/thread/${props.id}`)}
      >
        <Header>
          <Col md="10">
            <Title>{props.title}</Title>
          </Col>
        </Header>
        <DescriptionWrapper>
          <Description md="10">{props.text}</Description>
        </DescriptionWrapper>
      </div>

      {/* <Participants items={props?.participants || []} /> */}

      <Footer>
        <div
          style={{ cursor: "pointer" }}
          onClick={() => navigate(`/thread/${props.id}`)}
        >
          <FiMessageCircle />
          <Info>
            {props?.posts?.length || 0}
            <span> Replies</span>
          </Info>
          <FaRegClock />
          <Info>
            <span>Last updated </span>
            {lastUpdatedAt} ago
          </Info>
        </div>

        <div>
          {props.editable && (
            <EditIcon onClick={() => props.onEdit(props.id)} />
          )}
          {props.deletable && (
            <TrashIcon onClick={() => props.onDelete(props.id)} />
          )}
        </div>
        {/* <PinIcon /> */}
      </Footer>
    </Container>
  )
}

export default ThreadItem

import _ from "lodash"
import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api-graphql"
import Amplify, { Auth } from "aws-amplify"
import { AWS_CONFIG } from "../../../aws-config"
import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { Col, Row } from "react-bootstrap"
import { H1, H2, H7 } from "components/typography"
import ThreadItem from "./ThreadItem"
import ThreadAction from "./ThreadAction"
import { PageProps } from "models/PageProps"
import { breakpoints } from "utils"
import { Button } from "components/buttons"
import {
  apiFetchForumThreads,
  apiFetchMyForumActivity,
  apiPostNewForumThread,
  apiRemoveForumThread,
  apiPutForumThread,
} from "api/queries"
import { PinkAppButton } from "components/buttons"
import { ForumThread, ForumActivity } from "api/types"
import ConfirmModal from "components/ConfirmModal"

import getProfileFromSessionStorage from "components/QueryUserProfile/getProfileFromSessionStorage"
import Loader from "components/Loader"
import { navigate } from "@reach/router"
import {
  DiscussionForumEditTopic_InternalLink,
  DiscussionForumThread_InternalLink,
  Login_InternalLink,
  Profile_InternalLink,
  MonashPrivacyPolicy_ExternalLink,
} from "../../utils/urls"

import { UserType } from "../../api/types/user"
import TopicForm from "./EditTopic/TopicForm"

const Container = styled(Row)`
  @media (max-width: ${breakpoints.desktop}px) {
    padding: 0 16px;
  }
`
const Description = styled.p`
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: ${props => props.theme.colors.secondary};
  margin-bottom: 12px;
`
const Content = styled(Col)``
const ContentFooter = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 24px;
  margin-bottom: 72px;
`
const Sidebar = styled(Col)``
const SidebarContent = styled.div`
  margin-top: 24px;
`
const Header = styled(Col)`
  display: flex;
  align-items: left;
  text-align: left;
  flex-direction: column;
  margin: 38px 0 36px;
  @media (max-width: ${breakpoints.tablet}px) {
    flex-direction: column;
    align-items: flex-start;
    margin: 24px 0;
  }
`
const ButtonWrapper = styled(Col)`
  display: flex;
  align-items: center;
  @media (max-width: ${breakpoints.phone}px) {
    margin-bottom: 10px;
  }
`

const renderActivity = (actions: any[]) =>
  actions.map((action, i) => <ThreadAction {...action} key={i.toString()} />)

const p = {
  items: [],
  nextToken: null,
}

const ForumPage: React.FC<PageProps> = () => {
  const [threads, setThreads] = useState<ForumThread[] | null>([])
  const [activity, setActivity] = useState<ForumActivity[] | null>(null)
  const [searchIsVisible, setSearchIsVisible] = useState<boolean>(false)
  const [reachedEnd, setReachedEnd] = useState<boolean>(false)

  const [nextToken, setNextToken] = useState<string | null>(null)
  const [prevTokens, setPrevTokens] = useState<(string | null)[]>([null])
  const [loading, setLoading] = useState<boolean>(true)
  const [form, setForm] = useState({
    title: "",
    text: "",
  })

  const [confirmModalIsVisible, setConfirmModalIsVisible] = useState<boolean>(
    false
  )
  const [deleteThreadId, setDeleteThreadId] = useState<string>()
  const [threadModalVisible, setThreadModalVisible] = useState<boolean>(false)
  const [editingThread, setEditingThread] = useState<ForumThread | null>(null)

  const profileStr = getProfileFromSessionStorage()
  const loggedInUser = profileStr ? JSON.parse(profileStr) : null
  const role =
    typeof window !== `undefined`
      ? window.sessionStorage.getItem("UserRole")
      : ""

  const loadThreads = async (nextToken: string | null, append = true) => {
    setReachedEnd(true)
    setLoading(true)
    try {
      const response = await apiFetchForumThreads(
        {
          limit: 10,
          nextToken,
        },
        loggedInUser
          ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
          : GRAPHQL_AUTH_MODE.API_KEY
      )
      if ((response && response?.items?.length === 0) || !response) {
        setReachedEnd(true)
      } else {
        setReachedEnd(false)
      }
      let newItems = response?.items || []
      newItems.forEach(item => _.unset(item, "__typename"))
      if (append) {
        newItems = [...(threads || []), ...newItems] as ForumThread[]
      }
      setThreads(newItems)
      setNextToken(response?.nextToken || null)
      if (!response.nextToken) {
        setReachedEnd(true)
      }
      setLoading(false)
    } catch (err) {
      console.error("load threads:", err)
      setLoading(false)
    }
  }

  const loadMyForumActivity = async () => {
    const data = await apiFetchMyForumActivity({
      limit: 10000,
      nextToken: null,
    })
    const { items } = data
    setActivity(items)
  }

  useEffect(() => {
    Amplify.configure(AWS_CONFIG)
    if (
      typeof window !== `undefined` &&
      window.innerWidth <= breakpoints.tablet
    ) {
      setSearchIsVisible(true)
    }
    loadThreads(nextToken)
    if (loggedInUser) {
      loadMyForumActivity()
    }
  }, [])

  const onDeleteThreadConfirm = async () => {
    if (deleteThreadId) {
      apiRemoveForumThread(deleteThreadId).then(data => {
        if (data) {
          setConfirmModalIsVisible(false)
          loadThreads(null, false)
        }
      })
    }
  }

  const onNewThread = () => {
    setEditingThread(null)
    setThreadModalVisible(true)
  }

  const onEditThread = (thread: ForumThread) => {
    setEditingThread(thread)
    setThreadModalVisible(true)
  }

  const onDeleteThread = (threadId: string) => {
    setDeleteThreadId(threadId)
    setConfirmModalIsVisible(true)
  }

  const onThreadModalClose = () => setThreadModalVisible(false)
  const onThreadSubmit = async (form: any) => {
    try {
      if (!form.id) {
        const { id, ...params } = form
        await apiPostNewForumThread(params)
      } else {
        // Update thread
        await apiPutForumThread(form)
      }
    } catch (error) {
      console.log("error occurred when creating or updating thread", error)
    }

    setThreadModalVisible(false)
    loadThreads(null, false)
  }

  const renderThreads = (threads: any[]) =>
    threads.map((thread, i) => (
      <ThreadItem
        {...thread}
        key={i.toString()}
        editable={
          (loggedInUser && role?.toLowerCase() === "admin") ||
          thread?.author?.id === loggedInUser?.id
        }
        deletable={(loggedInUser && role?.toLowerCase() === "admin") as boolean}
        onDelete={onDeleteThread}
        onEdit={() => onEditThread(thread)}
        createdByLoggedInUser={
          (loggedInUser && loggedInUser?.id === thread?.author?.id) as boolean
        }
      />
    ))

  if (loading) {
    return (
      <Container>
        <Loader style={{ width: "100%" }} />
      </Container>
    )
  }

  return (
    <Container>
      <Header md="7">
        <H1>Discussion forum</H1>
        <br />
        <Description>
          We ask all users of this discussion forum to remain respectful of the
          privacy and safety of others. Do not include personal information or
          information that could reasonably identify another individual in your
          posts; such posts may be deleted.
        </Description>
        <Description>
          Content posted in this discussion forum reflects the views of
          individual users. MCHRI and Monash University take no responsibility
          for and do not support or guarantee the completeness, truthfulness,
          accuracy or reliability of any content posted by individual users on
          this forum.
        </Description>
        <Description>
          We are not obligated to monitor or screen this forum, but reserve the
          right to take down content or posts at any time, in its sole
          discretion for any reason and without notice. Neither the removal of
          content, nor failure to do so will result in liability to anyone.
        </Description>
      </Header>

      {loggedInUser && (
        <ButtonWrapper md="5">
          <PinkAppButton title="New Thread" onClick={onNewThread} />
        </ButtonWrapper>
      )}
      <Content md="7">
        {threads?.length && threads ? renderThreads(threads) : null}
        <ContentFooter>
          {!reachedEnd ? (
            <Button size="lg" onClick={() => loadThreads(nextToken)}>
              View more
            </Button>
          ) : (
            <Description>
              Before using the discussion forum, please read the{" "}
              <a
                href={MonashPrivacyPolicy_ExternalLink}
                target="_blank"
                rel="noreferrer"
              >
                <strong className="hover:underline cursor-pointer">
                  terms of use & privacy policy
                </strong>
              </a>
              .
            </Description>
          )}
        </ContentFooter>
      </Content>
      <Sidebar md="5">
        <H2>My Activity</H2>
        <SidebarContent>
          {activity?.length ? renderActivity(activity) : null}
        </SidebarContent>
      </Sidebar>

      <TopicForm
        data={editingThread || { id: "", title: "", text: "" }}
        show={threadModalVisible}
        onClose={onThreadModalClose}
        onSubmit={onThreadSubmit}
        isEditing={editingThread ? true : false}
      />
      <ConfirmModal
        title="Delete Thread"
        show={confirmModalIsVisible}
        onClose={() => setConfirmModalIsVisible(false)}
        onConfirm={onDeleteThreadConfirm}
      >
        Are you sure you want to delete this thread?
      </ConfirmModal>
    </Container>
  )
}

export default ForumPage

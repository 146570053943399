import Layout from "components/layout"
import SEO from "components/seo"
import React from "react"
import { Router } from "@reach/router"

import ThreadPage from "components/threadPage"
import ForumPage from "../../components/forumPage"

const DiscussionForum = () => {
  return (
    <Layout>
      <SEO title="Discussion Forum" />
      <Router>
        <ForumPage path="/discuss" />
        {/* @ts-ignore */}
        {/* <ThreadPage path="/discuss/:id" /> */}
      </Router>
    </Layout>
  )
}

export default DiscussionForum

import formatDistance from "date-fns/formatDistance"
import React from "react"
import styled from "styled-components"
// import { ThreadActionType } from "../../../models/IThreadAction"
import { ForumActivity } from "api/types"
import { Link, navigate } from "gatsby"

const Container = styled.div`
  margin-bottom: 16px;
`
const Header = styled.div`
  margin-bottom: 5px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.03em;
  color: ${props => props.theme.colors.secondary};
`
const ThreadTitle = styled.span`
  font-weight: bold;
  cursor: pointer;
`
const Content = styled.div`
  margin-bottom: 5px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.03em;
  color: #ec4d7b;
`
const Footer = styled.div`
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 17px;
  color: ${props => props.theme.colors.secondary};
`

const getTitlePrefix = (parentId: string | null) => {
  // switch (actionType) {
  //   case ThreadActionType.REPLY:
  //     return "Replied on"
  //   case ThreadActionType.BOOKMARK:
  //     return "Bookmarked"
  //   case ThreadActionType.CREATE_THREAD:
  //     return "Created thread"
  //   case ThreadActionType.UPDATE_THREAD:
  //     return "Updated thread"
  //   default:
  //     return ""
  // }
  if (parentId) return "Created thread"
  return "Replied on"
}

const ThreadAction: React.FC<ForumActivity> = props => {
  const titlePrefix = getTitlePrefix(props?.parentId)
  const timestamp = props?.createdOn
    ? formatDistance(new Date(props.createdOn), new Date())
    : ""

  return (
    <Container>
      <Header>
        {titlePrefix}{" "}
        <ThreadTitle onClick={() => navigate(`/thread/${props.threadId}`)}>
          {props.threadTitle}
        </ThreadTitle>
      </Header>
      {props.summaryText && <Content>{props.summaryText}</Content>}
      <Footer>{timestamp}</Footer>
    </Container>
  )
}

export default ThreadAction

import React from "react"
import { Modal } from "react-bootstrap"
import styled from "styled-components"
import { Button } from "components/buttons"

const Container = styled.div``

interface Props {
  show?: boolean
  onClose?: Function
  onConfirm?: Function
  title: string
  confirmText?: string
  cancelText?: string
}

const ConfirmModal: React.FC<Props> = props => {
  return (
    <Container>
      <Modal show={props.show} onHide={props.onClose}>
        <Modal.Header>{props.title}</Modal.Header>
        <Modal.Body>{props.children}</Modal.Body>
        <Modal.Footer>
          <Button onClick={props.onConfirm}>
            {props.confirmText || "Confirm"}
          </Button>
          <Button onClick={props.onClose}>
            {props.cancelText || "Cancel"}
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  )
}

export default ConfirmModal

import { Form } from "react-bootstrap"
import styled from "styled-components"

export const Label = styled(Form.Label)`
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: ${props => props.theme.colors.secondary};
  margin-bottom: 5px;
`
export const FormControl = styled(Form.Control)`
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 17px;
  color: ${props => props.theme.colors.secondary};
  &::placeholder {
    color: rgba(102, 102, 102, 0.5);
  }
`
